<template>
  <div>
    <div class="p-player-case_top">
      <div class="p-player-case_top-inner">
        <div class="label">素材分类:</div>
        <l-select
          v-model="puzzleTypeSelect"
          placeholder="请选择"
        >
          <l-select-option
            v-for="(item) in puzzleTypeSelectArr"
            :key="item.value"
            :value="item.value"
          >
            {{ item.name }}
          </l-select-option>
        </l-select>
      </div>
      <div class="p-player-case_top-inner"><l-range-picker v-if="resetStatus" allow-clear @change="dataTime" /></div>
      <div class="p-player-case_top-inner">
        <l-checkbox v-if="resetStatus" class="p-player-case_check" @change="checkButtom">
          只看创作型用户作品
        </l-checkbox>
      </div>
      <div class="p-player-case_top-inner">
        <l-button icon="search" class="p-player-case_buttom" @click="searchButtom">查询</l-button>
        <l-button class="p-player-case_buttom" @click="resetButtom">重置</l-button>
      </div>
    </div>
    <div class="p-player-case_centre">
      <div
        v-for="item in form"
        :key="item.id"
        class="p-player-case_container"
        @click="showModal(item.id)"
      >
        <div
          class="p-player-case_container-top"
          :style="{'background-image':'url'+'('+item.cover+')'}"
        >
        </div>
        <div class="p-player-case_container-bottom">
          <div class="p-player-case_container-bottom-text">作品名:{{ item.name }}</div>
          <div class="p-player-case_container-bottom-text">{{ item.cubeMatrixWidth }}X{{ item.cubeMatrixHeight }}</div>
        </div>
      </div>
    </div>
    <div class="p-player-case_bottom">
      <l-pagination
        v-model="pages.current"
        :page-size="pages.size"
        :total="pages.pagesTotal"
        show-less-items
        @change="pageBottom"
      />
    </div>
    <!-- /弹窗设置区 -->
    <l-modal
      v-model="visible"
      title="详情"
      :width="600"
    >
      <div class="p-player-case_modal">
        <div
          class="p-player-case_modal-left"
          :style="{'background-image':'url'+'('+detailData.cover+')'}"
        ></div>
        <div class="p-player-case_modal-right">
          <div class="p-player-case_modal-up">
            <div class="p-player-case_modal-text">作品名称 :{{ detailData.name }}</div>
            <div class="p-player-case_modal-text">作品ID :{{ detailData.id }}</div>
            <div class="p-player-case_modal-text">创作用户ID :{{ detailData.ganUserId }}</div>
            <div class="p-player-case_modal-text">创作用户账号 :{{ detailData.userAccount }}</div>
            <div class="p-player-case_modal-text">创作用户类型 :{{ detailData.userType }}</div>
            <div class="p-player-case_modal-text">创作时间 :{{ detailData.creationTime }}</div>
            <div class="p-player-case_modal-text">需要魔方数量 :{{ detailData.cubeMatrixHeight*detailData.cubeMatrixWidth }}</div>
            <div class="p-player-case_modal-text">横向魔方数量 :{{ detailData.cubeMatrixHeight }}</div>
            <div class="p-player-case_modal-text">纵向魔方数量 :{{ detailData.cubeMatrixWidth }}</div>
            <div class="p-player-case_modal-text">分辨率 :{{ detailData.pixelMatrixWidth }}X{{ detailData.pixelMatrixHeight }}</div>
          </div>
          <div class="p-player-case_modal-down">
            <l-button
              class="p-player-case_download"
              @click="downLoadImg"
            >
              下载作品
            </l-button>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div></div>
      </template>
    </l-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      resetStatus: true,
      isCreated: false, // 是否只看创作型用户作品 1是 0否
      pages: {
        current: 1, // 默认分页高亮页数
        size: 100, // 每页条数
        pagesTotal: 50, // 总数居数目
      },
      visible: false, // 是否显示弹窗
      resourceUrl: '', // 存放图片下载的路径
      form: [], // 存放页面列表展示数据
      startDay: '', // 选中的开始时间
      endDay: '', // 选中的结束时间
      detailData: {}, // 存储弹窗的案例详情信息
      caseId: '', // 存储弹窗详细信息对应的id号
      argumentData: '',
      puzzleTypeSelect: -1, // 类型（1、拼图 2、拼字）
      puzzleTypeSelectArr: [
        {
          value: -1,
          name: '全部'
        },
        {
          value: 1,
          name: '拼图'
        },
        {
          value: 2,
          name: '拼字'
        },
      ]
    }
  },
  mounted() {
    this.showList()
  },
  methods: {
    // 展示列表-------------------
    async showList() {
      const data = await this.$store.dispatch('playerCaseManage/playerCaseManageList', {
        startDay: this.startDay,
        endDay: this.endDay,
        onlyCreation: Number(this.isCreated),
        page: this.pages.current,
        size: this.pages.size,
        puzzleType: this.puzzleTypeSelect === -1 ? '' : this.puzzleTypeSelect,
      })
      this.form = data.list || []
      this.pages.size = 100
      this.pages.pagesTotal = data.count
      // console.log(this.pages.pagesTotal)
    },
    // 展示列表结束区-------------
    // 选择日期弹窗触发事件
    dataTime(data, e) {
      this.startDay = new Date(this.$utils.formatDate(new Date(e[0]).getTime(), 'yyyy/MM/dd 00:00:00')).getTime()
      this.endDay = new Date(this.$utils.formatDate(new Date(e[1]).getTime(), 'yyyy/MM/dd 23:59:59')).getTime()
      this.argumentData = data
    },
    // 复选框变化时回调函数
    checkButtom(e) {
      this.isCreated = e.target.checked
    },
    // 重置按钮
    resetButtom() {
      // TODO 临时方案 点击重置之后，重置“日期选择组件” 以及“checkbox 组件”状态
      this.resetStatus = false
      this.$nextTick(() => {
        this.resetStatus = true
      })

      this.startDay = ''
      this.endDay = ''
      this.times = []
      this.isCreated = false
      this.pages.current = 1
      this.pages.size = 100
      this.puzzleTypeSelect = -1
      this.showList()
    },
    // 查询按钮
    searchButtom() {
      this.showList()
    },
    // 分页按钮
    pageBottom(page, pageSize) {
      this.pages.current = page
      this.pages.size = pageSize
      this.showList()
      // console.log(page, pageSize)
    },
    // 弹窗按钮
    async showModal(id) {
      this.caseId = id
      // 获取对应详情信息
      const data = await this.$store.dispatch('playerCaseManage/playerCaseManageDetail', {
        id: id,
      })
      this.detailData = data
      // console.log(this.detailData)
      this.visible = true
    },
    // 获取后端数据库图片数据的存放路径
    async downLoadImg() {
      this.resourceUrl = '/api/admin/instance/ganCreation/download'
      const downObject = {
        url: this.resourceUrl,
        id: this.caseId,
      }
      // console.log(downObject)
      this.$store.dispatch('playerCaseManage/playerCaseManageDownload', downObject)
    },
  },

}
</script>

<style lang='scss'>
.p-player-case_top{
  width: 93%;
  height: 50px;
  display: flex;
  align-items:center;
  margin-bottom: 10px;
  // border-bottom: 1px solid #000;
  // background-color: rgb(165, 40, 40);
  .p-player-case_top-inner{
    width: 33%;
    height: 30px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 24px;
    line-height: 30px;
    .p-player-case_check{
      color: #189AFF;
    }
    .p-player-case_buttom{
      margin-left: 10px;
      color: #000;
    }

    .label {
      margin-right: 10px;
      font-size: 14px;
    }

    .ant-select {
      width: 160px;
    }
  }
}
.p-player-case_centre{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .p-player-case_container{
    width: 130px;
    height: 190px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    // background-color: rgb(199, 60, 60);
    border:1px solid #E8E8E8;
    .p-player-case_container-top{
      width: 120px;
      height: 130px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // background-image: url('https://tse1-mm.cn.bing.net/th/id/OIP-C.Io2vuazFp23bgGddKSf6ygHaL2?w=182&h=292&c=7&r=0&o=5&dpr=1.25&pid=1.7');
      // background-color: #E8E8E8;
    }
    .p-player-case_container-bottom{
      width: 130px;
      height: 40px;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .p-player-case_container-bottom-text{
        width: 130px;
       height: 20px;
       line-height: 20px;
       text-align: center;
       font-size: 12px;
       overflow: hidden;
      }
    }
  }
}
.p-player-case_bottom{
width: 100%;
height: 50px;
// background-color: #bfa;
display: flex;
justify-content:center;
align-items: center;
margin-top: 10px;
}
.p-player-case_modal{
  width: 552px;
  height: 400px;
  display: flex;
  .p-player-case_modal-left{
    width: 300px;
    height: 400px;
    // background-color: #bfa;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // background-image: url('https://tse2-mm.cn.bing.net/th/id/OIP-C.DF0FxXg1JP38vTtuCvtBfQHaEK?w=182&h=102&c=7&r=0&o=5&dpr=1.25&pid=1.7');
  }
  .p-player-case_modal-right{
    width: 252px;
    height: 400px;
    // background-color: #bfd;
    .p-player-case_modal-up{
      width: 252px;
      height: 300px;
      // background-color: #bfa;
      .p-player-case_modal-text{
        width: 252px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        text-align: left;
        color: #000;
        padding-left: 15px;
        overflow: hidden;
      }
    }
    .p-player-case_modal-down{
      width: 252px;
      height: 100px;
      padding-left: 15px;
      // background-color: rgb(247, 184, 184);
      .p-player-case_download{
        width: 100px;
        height: 30px;
        margin-top: 30px;
        color: #000;
      }
    }
  }
}

</style>
